import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function Menu() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <nav className="navbar sticky-top z-1">

                <ul className='navbar-menu kituA' >
                    <li><Link to="/">HOME</Link></li>
                    <li><Link to="/about">ABOUT US</Link></li>
                    {/* <li>
                        <div className="dropdown">
                            <button className="action-btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: 'none', border: 'none', outline: 'none', fontSize: '16px', cursor: 'pointer', fontWeight: "600" }} >
                                ACTION
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"> <Link className="dropdown-item" to="/event">LATEST NEWS AND EVENTS</Link> <Link className="dropdown-item" to="/communityoutreach">COMMUNITY OUTREACH</Link> <Link className="dropdown-item" to="/studyandresearchcenter">STUDY RESEARCH CENTER</Link> </div> </div>
                    </li> */}
                    <li><Link to="/event">ACTIVITIES</Link></li>
                    <li><Link to="/communityoutreach">COMMUNITY OUTREACH</Link></li>
                    <li><Link to="/studyandresearchcenter">STUDY RESEARCH CENTER</Link></li>
                    <li><Link to="/gallery">GALLERY</Link></li>
                    <li><Link to="/contact">CONTACT US</Link></li>
                </ul>

            </nav>

        </>
    )
}

export default Menu