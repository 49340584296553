import React, { useState } from "react";
import "../styles/Modal.css";
import axios from "axios";
import { Button } from "react-bootstrap";

const ModelDonet = ({ show, onClose }) => {
  const [Name, setName] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Gender, setGender] = useState("");
  const [Company, setCompany] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Address, setAddress] = useState("");
  const [amount, setamount] = useState("");
  // Function to generate a unique user ID
  function generateUserID() {
    const prefix = "USER";
    const timestamp = Date.now(); // Current timestamp
    const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    return `${prefix}-${timestamp}-${randomNum}`;
  }

  // Function to generate a unique order ID
  function generateOrderID() {
    const prefix = "ORDER";
    const timestamp = Date.now(); // Current timestamp
    const randomNum = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    return `${prefix}-${timestamp}-${randomNum}`;
  }

  // Example usage
  const userID = generateUserID();
  const orderID = generateOrderID();

  const Membership = async () => {
    if (!Name) {
      return alert("Please enter your name");
    }
    // if (!MobileNo) {
    //   return alert("Please enter your mobile number");
    // }
    // if(!validateIndianMobileNumber(MobileNo)){
    //   return alert("Invalid Mobile No")
    // }
    // if (!Gender) {
    //   return alert("Please select gender");
    // }
    if (!amount) {
        return alert("Please enter amount");
      }
    try {
   

      const config1 = {
        method: "post",
        url: "/addpaymentphonepay",
        baseURL: "https://thekitu.com/api/user", // Keep baseURL here
        headers: { "Content-Type": "application/json" }, // Corrected header
        data: {
          userId: userID,
          username: Name,
          Mobile: MobileNo,
          orderId: orderID,
          amount: amount,
        },
      };

      let res = await axios(config1);
      if (res.status === 200) {
        onClose();
        const config = {
            method: "post",
            url: "/addmembership",
            baseURL: "https://thekitu.com/api/user", // Keep baseURL here
            headers: { "Content-Type": "application/json" }, // Corrected header
            data: {
              name: Name,
              mobileno: MobileNo,
              gender: Gender,
              company: Company,
              designation: Designation,
              address: Address,
              amount: amount,
              formtype:"Donation",
              payid:res?.data?.id
            },
          };
        sessionStorage.setItem("config", JSON.stringify(config));
        sessionStorage.setItem("id", res?.data?.id);
        window.location.assign(res.data.url?.url);
      }
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    }
  };

  if (!show) {
    return null;
  }

  function validateIndianMobileNumber(mobileNumber) {

    const indianMobileRegex = /^[6-9]\d{9}$/;
  
    // Test the input against the regex
    if (indianMobileRegex.test(mobileNumber)) {
      return true; // Valid number
    } else {
      return false; // Invalid number
    }
  }
  

  return (
    <div className="modal-overlay"  onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{ width: "530px", backgroundColor: "white" ,marginTop:"150px"}}
      >
        <div className="modal-header">
         

          <button className="close-button"  onClick={onClose}>
            <i
              class="fas fa-times"
              style={{ color: "red", fontSize: "24px" }}
            />
          </button>
        </div>
        <h3 class="text-center" style={{color:"red"}}><u>Support Our Cause</u></h3>
        <p class="text-center">Your donation helps us continue our mission.</p>
        <div className="modal-body">
          <form>
            <label>
              Name{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setName(e.target.value)}
              value={Name}
              placeholder="Enter your name.."
              type="text"
              required
            />

            <label>
              Contact Number{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setMobileNo(e.target.value)}
              type="tel"
              maxLength={10}
              placeholder="Enter your number"
              required
            />

            {/* <label>
              Gender{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <select onChange={(e) => setGender(e.target.value)} required>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select> */}

            {/* <label>Company </label>
            <input
              onChange={(e) => setCompany(e.target.value)}
              value={Company}
              type="text"
              placeholder="Enter your company name"
            /> */}

            {/* <label>Designation </label>
            <input
              onChange={(e) => setDesignation(e.target.value)}
              type="text"
              placeholder="Enter your designation"
            /> */}

            {/* <label>
              Residential Address */}
              
            {/* </label>
            <textarea
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter your full address"
            ></textarea> */}

            <label>

              Amount{" "}
              <i
                class="fas fa-asterisk"
                style={{ color: "red", fontSize: "10px" }}
              />
            </label>
            <input
              onChange={(e) => setamount(e.target.value)}
              type="number"
              required
              min={1}
              placeholder="Enter donation amount"
            />
            <Button variant="danger" onClick={Membership}>
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModelDonet;
